<template>
  <div class="mt-4">

    <h4 class="text-center mb-3">Привязка преподавателя к группам</h4>
    <h5 class="text-center mb-4">
      <b>{{curriculumDisciplinesData?.discipline?.code}}</b>
      {{curriculumDisciplinesData?.discipline?.name}} ({{curriculumDisciplinesData?.discipline?.language?.native_name}})
      семестр {{semester}}
    </h5>

    <div class="mb-4">
      <router-link :to="`/publish-to-moodle?discipline_id=${discipline_id}&semester=${semester}`">
        Публикация курсов в Moodle
      </router-link>
    </div>

    <div class="mb-4">
      <router-link :to="`/education-courses-list?discipline_id=${discipline_id}&semester=${semester}`">
        Список курсов
      </router-link>
    </div>

    <div v-if="curriculumDisciplinesData.teachers.length">

      <div class="row border m-2 mb-4 rounded"
           v-for="(teacher, teacherIndex) in curriculumDisciplinesData.teachers" :key="teacherIndex">
        <div class="col col-md-2 p-2">
          <h5 class="p-3">{{teacher.pps.ppsData.lastname + ' ' + teacher.pps.ppsData.firstname}}</h5>
        </div>
        <div class="col-md-10">
          <div class="row p-2">
            <div class="col col-md-3 p-2"
                 v-for="(group, groupIndex) in curriculumDisciplinesData.groups" :key="groupIndex">
              <div>
                <span>{{group.group_name || 'Нет названия группы'}}</span>&nbsp;
                <span v-if="group.id">
                  <i class="pi pi-star" :style="{ color: group.mdl_course_id ? '#6ebe71' : '#fdd87d' }"></i>
                </span>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="checkbox"
                       @change="changeGroup(group, 'lecture_pps_id', +teacher.pps_id, $event)"
                       :checked="group.lecture_pps_id==teacher.pps_id"
                       :disabled="(group.lecture_pps_id!=teacher.pps_id&&group.lecture_pps_id)||group.mdl_course_id">
                <label class="form-check-label">
                  Лекция
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="checkbox"
                       @change="changeGroup(group, 'seminar_pps_id', +teacher.pps_id, $event)"
                       :checked="group.seminar_pps_id==teacher.pps_id"
                       :disabled="(group.seminar_pps_id!=teacher.pps_id&&group.seminar_pps_id)||group.mdl_course_id">
                <label class="form-check-label">
                  Практика
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="text-center mt-2 mb-3">
        <button v-if="saveVisibleForRole" type="button" class="btn btn-primary m-1" @click="saveCourse">
          Сохранить
        </button>
        <!--        <button v-if="uploadToMoodleVisibleForRole" type="button" class="btn btn-primary m-1"-->
        <!--                @click="createMoodleCourse"-->
        <!--                :disabled="publishToMoodle">-->
        <!--                    <span v-if="publishToMoodleBtnDisabled" class="spinner-grow spinner-grow-sm" role="status"-->
        <!--                          aria-hidden="true"></span>-->
        <!--          {{ publishToMoodleBtnDisabled ? 'Публикация в Moodle' : 'Опубликовать в Moodle' }}-->
        <!--        </button>-->
      </div>

    </div>

  </div>
</template>

<script>
  import {mapGetters, mapActions, mapMutations, mapState} from "vuex";
  import {decryptCheckRole} from "@/utils/helpers/decryptedRoles.helpers";

  export default {
    name: "BindGroups",
    data() {
      return {
        discipline_id: this.$route.query.discipline_id || 0,
        semester: this.$route.query.semester || 0,
        publishToMoodleBtnDisabled: false
      }
    },
    computed: {
      ...mapState('educationCurriculum', ['curriculumDisciplinesData']),
      saveVisibleForRole() {
        return decryptCheckRole('admin') || decryptCheckRole('departmentHead') || decryptCheckRole('adviser')
        //return ['admin', 'departmentHead'].some(this.checkRole)


      },
      uploadToMoodleVisibleForRole() {
        return decryptCheckRole('admin') || decryptCheckRole('officeRegistration') || decryptCheckRole('officeRegistrationHead')
      },
      publishToMoodle() {
        return (this.curriculumDisciplinesData.groups.filter(i => i.mdl_course_id).length && !this.curriculumDisciplinesData.groups.filter(i => i.id && !i.mdl_course_id).length)
          || this.publishToMoodleBtnDisabled
      }
    },
    methods: {
      ...mapMutations('educationCurriculum', ['CHANGE_GROUP']),
      ...mapActions('educationCurriculum', ['POST_EDUCATION_COURSE', 'PUT_EDUCATION_COURSE',
        'GET_TEACHERS_GROUPS_FLOWS_BY_DISCIPLINE', 'UPLOAD_TO_MOODLE']),
      changeGroup(item, prop, val, e) {
        let value = e.target.checked ? val : null
        this.CHANGE_GROUP({item, prop, value})
      },
      groupBy(arr, key) {
        return Object.values(arr.reduce((acc, item) => {
          (acc[item[key]] = acc[item[key]] || []).push(item)
          return acc
        }, {}))
      },
      async saveCourse() {
        console.log(this.curriculumDisciplinesData, 'curriculumDisciplinesData')

        const coursesCreate = JSON.parse(JSON.stringify(this.curriculumDisciplinesData.groups.filter(i => i.lecture_pps_id && !i.id)))
          .map(i => ({...i, education_discipline_id: this.discipline_id}))

        if (coursesCreate.length) {
          await this.POST_EDUCATION_COURSE(coursesCreate)
        }

        const coursesUpdate = this.curriculumDisciplinesData.groups.filter(i => !!i.id)

        if (coursesUpdate.length) {
          await this.PUT_EDUCATION_COURSE(coursesUpdate)
        }

        if (this.discipline_id) {
          await this.GET_TEACHERS_GROUPS_FLOWS_BY_DISCIPLINE({
            discipline_id: this.discipline_id,
            semester: this.semester
          })
        }
        this.$message({text: 'Данные успешно обновились'})

        console.log(coursesCreate, 'coursesCreate')
        console.log(coursesUpdate, 'coursesUpdate')
      },
      async createMoodleCourse() {
        const courses = this.curriculumDisciplinesData.groups.filter(i => i.id && !i.mdl_course_id && i.lecture_pps_id && i.seminar_pps_id)
        const coursesCreate = JSON.parse(JSON.stringify(courses))
          .map(i => ({...i, education_discipline_id: this.discipline_id}))
        if (coursesCreate.length) {
          this.publishToMoodleBtnDisabled = true
          for (let c of coursesCreate) {
            await this.UPLOAD_TO_MOODLE([c])
          }
          if (this.discipline_id) {
            await this.GET_TEACHERS_GROUPS_FLOWS_BY_DISCIPLINE({
              discipline_id: this.discipline_id,
              semester: this.semester
            })
          }
          this.publishToMoodleBtnDisabled = false
          this.$message({text: 'Данные успешно опубликованы в Moodle'})
        } else {
          this.$message({text: ' '})
        }

      }
    },
  }
</script>

<style scoped>

</style>