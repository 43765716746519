<template>
    <div class="mt-4">

        <h4 class="text-center mb-4">Привязка преподавателя к потокам</h4>
        <div v-if="curriculumDisciplinesData.teachers.length">
            <div class="row border m-2 mb-4 rounded"
                 v-for="(teacher, teacherIndex) in curriculumDisciplinesData.teachers"
                 :key="teacherIndex">
                <div class="col col-md-2 p-2">
                    <h5 class="p-3">{{teacher.pps.ppsData.lastname + ' ' + teacher.pps.ppsData.firstname}}</h5>
                </div>
                <div class="col-md-10">
                    <div class="row p-2">
                        <div class="col col-md-3 p-2"
                             v-for="(flow, flowIndex) in curriculumDisciplinesData.flows"
                             :key="flowIndex">
                            <div>
                                <strong>{{flow.flow_name||'Нет названия потока'}}</strong>
                                <div class="mt-2 mb-2">
                                    <div class="mb-2" v-for="(group, groupIndex) in flow.groups" :key="'group'+groupIndex">
                                        {{group.student_groups_name}}
                                    </div>
                                </div>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox"
                                       @change="changeFlow(flow, 'lecture_pps_id', teacher.pps_id, $event)"
                                       :checked="flow.lecture_pps_id==teacher.pps_id"
                                       :disabled="flow.lecture_pps_id!=teacher.pps_id&&flow.lecture_pps_id">
                                <label class="form-check-label">
                                    Лекция
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox"
                                       @change="changeFlow(flow, 'practice_pps_id', teacher.pps_id, $event)"
                                       :checked="flow.practice_pps_id==teacher.pps_id"
                                       :disabled="flow.practice_pps_id!=teacher.pps_id&&flow.practice_pps_id">
                                <label class="form-check-label">
                                    Практика
                                </label>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

            <div class="text-center mt-2 mb-3">
                <button type="button" class="btn btn-primary">Сохранить</button>
            </div>
        </div>


    </div>
</template>

<script>
    import {mapGetters, mapActions, mapMutations, mapState} from "vuex";

    export default {
        name: "BindFlows",
        computed: {
            ...mapState('educationCurriculum', ['curriculumDisciplinesData']),
        },
        methods: {
            ...mapMutations('educationCurriculum', ['CHANGE_FLOW']),
            changeFlow(item, prop, val, e) {
                let value = e.target.checked ? val : null
                this.CHANGE_FLOW({item, prop, value})
            }
        },
    }
</script>

<style scoped>
</style>