<template>
    <div class="container">
        <div v-if="loading" class="d-flex justify-content-center mt-5">
            <Preloader/>
        </div>
        <div v-else>
            <BindFlows v-if="false"/>
            <BindGroups v-else/>
        </div>

    </div>
</template>

<script>
    import {mapGetters, mapActions, mapMutations, mapState} from "vuex";
    import BindGroups from '@/components/education-programme/curriculum/BindGroups.vue'
    import BindFlows from '@/components/education-programme/curriculum/BindFlows.vue'

    export default {
        name: "BindToCurriculum",
        components: {
            BindGroups, BindFlows
        },
        data() {
            return {
                disciplineId: +this.$route.query.discipline_id || 0,
                semester: this.$route.query.semester || 0,
                loading: true
            }
        },
        computed: {
            ...mapState('educationCurriculum', ['curriculumDisciplinesData']),
            isFlow() {
                return this.curriculumDisciplinesData.discipline.is_thread
            }
        },
        methods: {
            ...mapActions('educationCurriculum', ['GET_TEACHERS_GROUPS_FLOWS_BY_DISCIPLINE']),
        },
        async mounted() {
            if (this.disciplineId) {
                await this.GET_TEACHERS_GROUPS_FLOWS_BY_DISCIPLINE({discipline_id: this.disciplineId, semester: this.semester})
            }
            this.loading = false
        }
    }
</script>

<style scoped>

</style>